import React from 'react'

const SignoutRedIcon = (props) => {
  return (
    <div {...props}>
      <svg width="22" height="20" viewBox="0 0 22 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M7.78007 1.59998H4.07419C3.51256 1.59998 2.97392 1.82122 2.57679 2.21505C2.17965 2.60888 1.95654 3.14302 1.95654 3.69998V16.3C1.95654 16.8569 2.17965 17.3911 2.57679 17.7849C2.97392 18.1787 3.51256 18.4 4.07419 18.4H7.78007M8.04326 9.99998H20.0433M20.0433 9.99998L15.4581 5.19998M20.0433 9.99998L15.4581 14.8" stroke="#FF4065" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
      </svg>
    </div>
  )
}

export default SignoutRedIcon