import React, { useState } from "react";
import PropTypes from 'prop-types';
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  // DropdownItem,
  // DropdownItem,
} from "reactstrap";

import { withTranslation } from "react-i18next";
import { connect, useSelector } from "react-redux";

import unleashLogo from "../../../assets/images/icons/unleash-logo.png";
import withRouter from "../../../helpers/withRouter";
import { useSignOut } from "../../../hooks/useSignout";
import capitalizeFirstLetter from "../../../helpers/capitalizeFirstLetter";
import SignoutRedIcon from "../../atoms/Svg/SignoutRedIcon";
import ProfilePlainIcon from "../../atoms/Svg/ProfilePlainIcon";

const ProfileMenu = props => {
  // Declare a new state variable, which we'll call "menu"
  const signOut = useSignOut();
  const role = useSelector((state) => state.Auth.role);
  // const userAuth = useSelector((state) => state.Auth);

  const [menu, setMenu] = useState(false);

  return (
    <React.Fragment>
      <Dropdown
        isOpen={menu}
        toggle={() => setMenu(!menu)}
        className="d-inline-block"
      >
        <DropdownToggle
          className="btn header-item "
          id="page-header-user-dropdown"
          tag="button"
        >
          <img
            className="rounded-circle header-profile-user"
            src={unleashLogo}
            alt="Header Avatar"
          />
          <span className="d-none d-xl-inline-block ms-1 me-1">
            {role === "admin" ? "Super Admin"  : role === "marketing" ? "Marketing Admin" : capitalizeFirstLetter(role)}
            </span>
          <i className="mdi mdi-chevron-down d-none d-xl-inline-block" />
        </DropdownToggle>
        <DropdownMenu className="dropdown-menu-end">
          {/* 
          <DropdownItem tag="a" href="/crypto-wallet">
            <i className="bx bx-wallet font-size-16 align-middle me-1" />
            {props.t("My Wallet")}
          </DropdownItem>
          <DropdownItem tag="a" href="#">
            <span className="badge bg-success float-end">11</span>
            <i className="bx bx-wrench font-size-16 align-middle me-1" />
            {props.t("Settings")}
          </DropdownItem>
          <DropdownItem tag="a" href="auth-lock-screen">
            <i className="bx bx-lock-open font-size-16 align-middle me-1" />
            {props.t("Lock screen")}
          </DropdownItem> */}
          <DropdownItem className="d-flex align-items-center gap-3" tag="button" onClick={() => props.router.navigate(`/${role}/profile`)}>
            {" "}
            <ProfilePlainIcon/>
            {props.t("Profile")}{" "}
          </DropdownItem>
          <div className="dropdown-divider" />
          <button className="dropdown-item d-flex gap-3 align-items-center" onClick={signOut}>
            <SignoutRedIcon/>
            <span>{props.t("Logout")}</span>
          </button>
        </DropdownMenu>
      </Dropdown>
    </React.Fragment>
  );
};

ProfileMenu.propTypes = {
  success: PropTypes.any,
  t: PropTypes.any
};

const mapStatetoProps = state => {
  return { };
};

export default withRouter(
  connect(mapStatetoProps, {})(withTranslation()(ProfileMenu))
);
