import React from 'react'
import Sidebar from '../organisms/protected/Sidebar'
import Header from '../organisms/protected/Header'
import { Outlet } from 'react-router-dom'
import styled from 'styled-components'

const ProtectedLayout = () => {
  return (
    <div id="layout-wrapper">
      <Header />
      <Sidebar />
      <StyledMainConent className="main-content">
        <Outlet />
      </StyledMainConent>
    </div>
  )
}

export default ProtectedLayout

const StyledMainConent = styled.div`
`