import { Spinner as SpinnerComponent } from "reactstrap";

const Spinner = (props) => {

  if (props.isLoading) {
    return (
      <div className="gap-2" style={{
        zIndex: 10000,
        position:"absolute",
        inset: 0,
        textAlign: "center",
        display:"flex",
        alignItems: "center",
        justifyContent:"center",
        flexDirection:"column",
        background: "rgba(241, 241, 241, 0.85)",
        borderRadius: "4px",
      }}>
        <SpinnerComponent size="sm" color="primary" style={{ background: "transparent" }}/>
        <span style={{ fontSize: 14 }}>
          {' '}{props.text ?? "Loading"}
        </span>
      </div>
    )
  }
  
}

export default Spinner