import {
  useDispatch as useReduxDispatch,
  useSelector as useReduxSelector,
  // eslint-disable-next-line
  TypedUseSelectorHook,
} from "react-redux"
import { configureStore } from "@reduxjs/toolkit"
import rootReducer from "./rootReducer"

//REDUX PERSIST
import storage from "redux-persist/lib/storage"
import {
  persistStore,
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from "redux-persist";
import autoMergeLevel2 from "redux-persist/es/stateReconciler/autoMergeLevel2"
import { apiCall, errorHandler } from "./apiCall";

const persistConfig = {
  key: "root",
  storage,
  stateReconciler: autoMergeLevel2, // merges [not replaced] incoming state 👉 initialState
  // blacklist: [""], // DONT PERSIST specificReducer (array of strings)
  whitelist: ['Auth'] // PERSIST specificReducer (array of strings)
}
const persistedRootReducer = persistReducer(persistConfig, rootReducer)

export const store = configureStore({
  reducer: persistedRootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }).concat(apiCall.middleware, errorHandler)
})

// ----------------------------
// Type for useSelector state autocompletion without TypeScript
/**@type TypedUseSelectorHook<ReturnType<typeof store.getState>> */
export const useSelector = useReduxSelector

/**@type typeof store.dispatch */
export const useDispatch = () => useReduxDispatch()

// export default store
export const persistor = persistStore(store)
