import { Button as ButtonComponent } from 'reactstrap';
import styled from 'styled-components';

const Button = ({
  color,
  children,
  outline=false,
  className,
  type,
  ...rest
}) => {

  return (
    <StyledButton 
      outline={outline}
      type={type}
      color={color}
      className={`waves-effect waves-light ${className}`}
      {...rest}
    >
      {children}
    </StyledButton>
  )
}

const StyledButton = styled(ButtonComponent)`
  outline: none !important;
  box-shadow: none !important;
  /* background: ${(props) => props.outline ? 'transparent': props.color === 'primary' ? '#313d85' : props.color === 'error' ? '#BC0000': 'transparent'};
  color: ${(props) => props.outline ? props.color === 'primary' ? '#003BBC': '#BC0000' : '#fff'};
  border-radius: 5px;
  border: ${(props) => props.outline ? '1px solid': 'unset'};
  border-color: ${(props) => props.color === 'primary' ? '#313d85' : props.color === 'error' ? '#BC0000': 'transparent'}; */
`

export default Button