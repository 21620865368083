import './App.css';
import { useRoutes } from 'react-router-dom';
import { authProtectedRoutes, publicRoutes } from "./routes";

import "./assets/scss/theme.scss";
import { Fragment } from 'react';
import DynamicErrorModal from './components/organisms/DynamicErrorModal';
import DynamicSuccessModal from './components/organisms/DynamicSuccessModal';

const App = () => {
  const content = useRoutes([...publicRoutes, ...authProtectedRoutes])

  return (
    <Fragment>
      <DynamicErrorModal/> 
      <DynamicSuccessModal/>
      {content}
    </Fragment>
  );
}

export default App;
