import { isRejectedWithValue } from "@reduxjs/toolkit";
import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { doSetModal } from "./slices/Modal";
import { doLogout } from "./slices/Auth";

const API_URL = process.env.REACT_APP_API;
// const API_URL = "http://localhost:5001/api";
const UNAUTHORIZED_STATUSES = [401, 403];

const prepareHeaders = (headers, { getState }) => {
  const token = getState().Auth.token;
  token && headers.set("authorization", `Bearer ${token}`);
  return headers;
}

export const apiCall = createApi({
  reducerPath: "api",
  baseQuery: fetchBaseQuery({
    baseUrl: API_URL,
    prepareHeaders,
  }),
  // keepUnusedDataFor: 3600, // 1 hour
  // tagTypes: ["Voucher", "Appointment", "Doctor", "User"],
  tagTypes: ["Merchant", "Dashboard"],
  endpoints: () => ({}),
});
 
export const errorHandler = (api) => (next) => (action) => {
  if (isRejectedWithValue(action)) {
    if (
      UNAUTHORIZED_STATUSES.includes(action.payload.status) &&
      api.getState().Auth.token !== undefined
    ) {
      api.dispatch(doSetModal(
        {
          isShowError: true,
          // title: action.payload?.data?.c,
          description: action.payload?.data?.m,
        }
      ))
      api.dispatch(doLogout())
    } else {
      api.dispatch(doSetModal(
        {
          isShowError: true,
          // title: action.payload?.data?.c || action.payload?.data?.statusCode || "Error",
          description: action.payload?.data?.m || action.payload?.data?.message || "Something went wrong. Please try again or reload the page.",
        }
      ))
    }
  }

  return next(action);
};