import { createSlice } from "@reduxjs/toolkit"
// import { doAuthLogin } from "../actions/Auth.action"

const initialState = {
  token: null,
  role: "",
  isLoading: false,
  isSuccess: false,
  message: "",
  isError: false,
  isAuthenticated: false,
  name: "",
  email: "",
  type: "",
  userInfo: null,
  isProfileFinish: false
}

const authSlice = createSlice({
  name: "Auth",
  initialState,
  reducers: {
    doSetAuthLogin: (state, { payload }) => {
      state.isLoading = false 
      state.role = payload.role
      state.isAuthenticated = true
      state.token = payload.tokens.access
      state.name = payload.name
      state.email = payload.email
      state.isSuccess = true
    },
    doSetFinishProfile: (state, { payload}) => {
      state.isProfileFinish = payload.isProfileFinish
    },
    doLogout: (state, { payload }) => {
      state.token = null
      state.role = ""
      state.isLoading = false
      state.isSuccess = false
      state.message = null
      state.isError = false
      state.isAuthenticated = false
      state.name = ""
      state.email = ""
      state.isProfileFinish = false
    },
    getUserMe: (state, { payload }) => {
      state.userInfo = payload
    }
  },
  extraReducers: (builder) => {
  },
})

export const { actions: authActions, reducer: authReducer } = authSlice

export const { doLogin, doLogout, doSetAuthLogin, getUserMe, doSetFinishProfile } = authActions

export default authSlice;