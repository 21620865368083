import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isShowError: false,
  isShowSuccess: false,
  title: "",
  description: "",
};

const modalSlice = createSlice({
  name: "Modal",
  initialState,
  reducers: {
    doSetModal: (state, action ) => {
      return {
        ...state,
        isShowError: action.payload.isShowError,
        isShowSuccess: action.payload.isShowSuccess,
        title: action.payload.title,
        description:  action.payload.description
      }
    },
    doSetResetModal: (state) => {
      state.isShowError = false;
      state.isShowSuccess = false;
      state.title = "";
      state.description = "";
    }
  },
});

export const { actions: modalActions, reducer: modalReducer } = modalSlice

export const { doSetModal, doSetResetModal, } = modalActions

export default modalSlice;