import React from "react";
import { useSelector } from "react-redux";
import { Navigate } from "react-router-dom";

const Authmiddleware = (props) => {
  const role = useSelector((state) => state.Auth.role);
  const isAuthenticated = useSelector((state) => state.Auth.isAuthenticated);

  if (isAuthenticated) {
    if (role === props.role) { // Checking currnet login role and rederict to authorized pages based on the current role
      return <React.Fragment>
      {props.children}
    </React.Fragment>
    } else {
      return <Navigate to="/unauthorized" />
    }
  } else {
    return <Navigate to={{ pathname: "/login", state: { from: props.location } }} />
  }
};

export default Authmiddleware;
